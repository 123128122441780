<template>
    <v-dialog
        v-model="show"
        max-width="400"
        >
        <v-card class="pa-6">
          <v-card-title>Registrar usuario comprador</v-card-title>
          <v-card-text>
            <p>El usuario que registres tendrá acceso a ver y realizar compras en Mangxo.</p>
            <v-form 
              ref="form"
              v-model="valid"
        
            >
    
              <v-text-field
                v-model="form.name"
                label="Nombre"
                :rules="rules.required"
              ></v-text-field>
    
              <v-text-field
                v-model="form.email"
                label="Email"
                :rules="rules.email"
              ></v-text-field>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="show = false">
              Cerrar
            </v-btn>
            <v-btn
                v-if="!inSubmit"
                text
                style="background: #732C9C; color: #fff"
                @click="handleSubmit"
                :disabled="inSubmit || !valid"
            >
              Guardar
            </v-btn>
            <v-progress-circular
                v-else
                indeterminate
                color="deep-purple"
                style="margin-left: 10px;"
            >
            </v-progress-circular>
          </v-card-actions>
        </v-card>
    </v-dialog>
    </template>
    
    <script>
    import CustomerService from "@/services/customerService"
    
    export default {
        props: [
          "visible"
        ],
    
        data() {
            return {
                form: {
                  name: "",
                  phone: ""
                },
                inSubmit: false,
                valid: false,
                rules: {
                  required: [ v => !!v || 'El campo es requerido'],
                  email: [v => /.+@.+\..+/.test(v) || 'Correo no válido']
                },
            }
        },
    
        methods: {
            async handleSubmit() {
                
                this.inSubmit = true

                const response = await CustomerService.createBuyerCustomer(this.form)
                const status = response.status

                const data = {
                    success: false,
                    msg: "Ocurrio un error"
                }

                if (status == 200) {
                    data.success = true
                    data.msg = "Usuario registrado"
                }

                this.inSubmit = false

                this.reset()
                this.$emit("close")
                this.$emit("showDialogAlert", data)
            },
    
            reset () {
              this.form.name = "",
              this.form.email = ""
            }
        },
    
        computed: {
          show: {
            get () {
              return this.visible
            },
            set (value) {
              if (!value) {
                this.$emit('close')
              }
            }
          },
        },
    }
    
    </script>