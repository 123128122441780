<template>
    <div class="text-center" v-if="account">
      <v-dialog
        v-model="show"
        @click:outside="show = false"
        max-width="400"
      >
        <v-card>
            <v-card-title class="text-h5 lighten-2" :style="`display: flex; justify-content: space-between; background: ${account.bank.background}; color: ${account.bank.background === '#fff' ? '#000' : '#fff'};`">
                
                <div>{{ account.bank.name }}</div>
              
                <v-spacer />
               <div>
                  <v-img :src="account.bank.logo_url" class="bank-logo" />
                </div> 
                
            </v-card-title>
          <v-container>
              <v-row justify="center">
                <v-card-text class="text-center">
                    <div>Numero de cuenta</div>
                    <div class="text-h6 mb-4">
                      {{ account.account_number }} 
                      <CopyBtn 
                        :content="account.account_number"
                      />
                    </div>
                    <div>CLABE</div>
                    <div class="text-h6 mb-4">
                      {{ account.account_key }}
                      <CopyBtn 
                        :content="account.account_key"
                      />
                    </div>
                    <div>Beneficiario</div>
                    <div class="text-h6 mb-4">
                      {{ account.beneficiary_name }}
                    </div>
                    <div>Moneda</div>
                    <div class="text-h6 mb-4 text-uppercase">
                      {{ account.currency }}
                    </div>
                </v-card-text>
             </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="$emit('close')"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
    
    import CopyBtn from "@/components/CopyBtn"

    export default {
      props: ['visible', 'account'],

      components: {
        CopyBtn
      },
  
      data() {
        return {}
      },    

      computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    }
  </script>

<style>
  .bank-logo {
    width: 100% !important;
    margin: auto;
    /* border-radius: 1em; */
    margin-left: 1em;
  }
</style>