var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"lg":"6","offset":"3"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center"},[(_vm.result.success)?_c('div',{staticClass:"text-h5 mt-2 font-weight-medium",staticStyle:{"color":"#732C9C"}},[_vm._v(" "+_vm._s(_vm.result.msg)+" "),_c('br'),(_vm.showAttachInvoiceButton)?_c('v-btn',{staticClass:"yellow amber lighten-1--text mt-2",on:{"click":function($event){_vm.showAttachInvoice = true}}},[_vm._v(" Adjuntar factura ")]):_vm._e()],1):_vm._e(),(!_vm.result.success)?_c('div',{staticClass:"text-h5 mt-2 red--text font-weight-medium"},[_vm._v("¡COMPRA RECHAZADA!")]):_vm._e(),(!_vm.result.success && _vm.result.msg)?_c('div',{class:{
                                    'text-h5': _vm.result.msg && _vm.result.msg.length <= 40, 
                                    'subtitle': _vm.result.msg && _vm.result.msg.length > 40,
                                    'mt-2': true,
                                    'red--text': true,
                                    'font-weight-medium': true
                                }},[_vm._v(_vm._s(_vm.result.msg))]):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12 text-center"}},[(!_vm.result.success)?_c('div',[_c('v-btn',{staticClass:"light mt-2",on:{"click":function($event){return _vm.$emit("goBack")}}},[_vm._v(" Editar compra ")])],1):_vm._e(),(_vm.result.success)?_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"light",attrs:{"disabled":_vm.isGeneratingPdf},on:{"click":function($event){return _vm.generateCreditCheck()}}},[_vm._v(" Imprimir comprobante ")])],1):_vm._e(),_c('div',{staticClass:"mt-2"},[(_vm.isGeneratingPdf)?_c('span',[_vm._v("Generando comprobante...")]):_vm._e()]),_c('div',{staticClass:"mt-2"},[(_vm.$UserManager.getRole() == 'admin-supplier')?_c('v-btn',{staticClass:"light",on:{"click":function($event){return _vm.$router.push({ name: 'dashboard'})}}},[_vm._v(" Volver a inicio ")]):_vm._e(),(_vm.$UserManager.getRole() == 'vendor-supplier')?_c('v-btn',{staticClass:"light mt-2",on:{"click":function($event){return _vm.$emit("goBack", true)}}},[_vm._v(" Volver ")]):_vm._e()],1),_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"deep-purple lighten-4",attrs:{"target":"_blank","href":"https://api.whatsapp.com/send/?phone=528119825427&text=%C2%A1Hola%21+Quiero+hablar+con+un+asesor"}},[_vm._v(" Contactar un asesor ")])],1)])],1)],1)],1)],1)],1),_c('AttachInvoice',{attrs:{"visible":_vm.showAttachInvoice,"item":_vm.result.data},on:{"close":function($event){_vm.showAttachInvoice = false},"success":function($event){_vm.dialogAlert = true; _vm.showAttachInvoiceButton = false}}}),_c('DialogAlert',{attrs:{"visible":_vm.dialogAlert,"message":_vm.dialogAlertContent},on:{"handleCloseDialogAlert":function($event){_vm.dialogAlert = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }