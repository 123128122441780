<template>
    <v-dialog v-model="isVisible" max-width="450px" transition="dialog-bottom-transition">
        <v-card>
            <v-card-title class="headline d-flex justify-space-between align-center">
                <span>Exportar Ventas</span>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pt-4">
                <v-radio-group v-model="exportOption" column>
                    <v-radio label="Ventas del día" value="day" prepend-icon="mdi-calendar-today" />
                    <v-radio label="Ventas del mes" value="month" prepend-icon="mdi-calendar-month" />
                    <v-radio label="Ventas del mes anterior" value="previous_month" prepend-icon="mdi-calendar-month" />
                    <v-radio label="Rango de fechas" value="range" prepend-icon="mdi-calendar-range" />
                </v-radio-group>

                <v-row v-if="exportOption === 'range'" class="mt-3">
                    <v-col cols="6">
                        <v-menu
                            ref="menuStart"
                            v-model="menuStart"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="formattedStartDate"
                                    label="Fecha inicio"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker locale="es" v-model="startDate" no-title scrollable @input="updateStartDate">
                                <v-btn text color="primary" @click="menuStart = false">Cancelar</v-btn>
                                <v-btn text color="primary" @click="$refs.menuStart.save(startDate)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="6">
                        <v-menu
                            ref="menuEnd"
                            v-model="menuEnd"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :value="formattedEndDate"
                                    label="Fecha fin"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                :min="startDate"
                                :max="endMaxDate"
                                v-model="endDate"
                                no-title
                                scrollable
                                locale="es"
                                @input="updateEndDate"
                            >
                                <v-btn text color="primary" @click="menuEnd = false">Cancelar</v-btn>
                                <v-btn text color="primary" @click="$refs.menuEnd.save(endDate)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <small>*La exportación está limitada a un rango máximo de 3 meses.</small>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-end">
                <v-btn text color="grey" @click="closeDialog">Cancelar</v-btn>
                <v-btn color="primary" :loading="loading" :disabled="loading" @click="exportPayments">
                    <v-icon left>mdi-file-excel</v-icon> Exportar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PaymentService from '../services/paymentService';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isVisible: this.visible,
            exportOption: 'day',
            startDate: null,
            endDate: null,
            menuStart: false,
            menuEnd: false,
            loading: false,
            threeMonthsFromNow: this.addMonths(new Date(), 3).toISOString().substr(0, 10),
        };
    },
    watch: {
        visible(val) {
            this.isVisible = val;
        },
        isVisible(val) {
            if (!val) this.$emit('close');
        },
        startDate(newDate) {
            this.endDate = newDate ? this.addMonths(new Date(newDate), 3).toISOString().substr(0, 10) : null;
        },
    },
    computed: {
        formattedStartDate() {
            return this.startDate ? this.formatDate(this.startDate) : '';
        },
        formattedEndDate() {
            return this.endDate ? this.formatDate(this.endDate) : '';
        },
        endMaxDate() {
            return this.startDate
                ? this.addMonths(new Date(this.startDate), 3).toISOString().substr(0, 10)
                : this.threeMonthsFromNow;
        },
    },
    methods: {
        closeDialog() {
            this.isVisible = false
            this.exportOption = 'day'
            this.startDate = null
            this.endDate = null
            this.menuStart = false
            this.menuEnd = false
            this.loading = false
            this.threeMonthsFromNow = null
        },
        async exportPayments() {
            const user = JSON.parse(localStorage.getItem('user'));
            const ownerId = user ? user.owner_id : null;

            if (!ownerId) {
                this.$emit('showDialogAlert', { success: false, msg: 'No se encontró owner_id en el usuario.' });
                return;
            }

            this.loading = true;
            try {
                const response = await PaymentService.exportData(this.exportOption, this.startDate, this.endDate, ownerId);

                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'payments.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);

                this.$emit('showDialogAlert', { success: true, msg: 'Exportación exitosa.' });
            } catch (error) {
                console.error('Error en la exportación:', error);
                this.$emit('showDialogAlert', { success: false, msg: error.message });
            } finally {
                this.loading = false;
            }
        },
        addMonths(date, months) {
            const newDate = new Date(date);
            newDate.setMonth(newDate.getMonth() + months);
            return newDate;
        },
        formatDate(date) {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        },
        updateStartDate(date) {
            this.startDate = date;
            // this.menuStart = false;
        },
        updateEndDate(date) {
            this.endDate = date;
            // this.menuEnd = false;
        },
    },
};
</script>

<style scoped>
.headline {
    font-weight: bold;
    color: #4a4a4a;
}
.v-btn {
    margin-left: 8px;
}
.v-card-actions {
    padding: 16px;
}
</style>
