const PaymentService = {

    url: `${process.env.VUE_APP_API_URL}payments/`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    create: async (data) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}create`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    createOnReservedCreditLine: async (data) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}create-on-reserved-credit-line`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    get: async (options) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const params =  new URLSearchParams(options)

            const response = await fetch(`${process.env.VUE_APP_API_URL}payments?${params}`, {
                headers: headers
            })
            return response
        } catch (e) {
            return false;
        }
    },

    getResume: async (user) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}resume`, {
                body: JSON.stringify(user),
                headers: headers
            })
            return response
        } catch (e) {
            return false;
        }
    },

    confirmPayment: async (payment_id) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}confirm-payment`, {
                method: "POST",
                body: JSON.stringify({payment_id}),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    rejectPayment: async (payment_id) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}reject-payment`, {
                method: "POST",
                body: JSON.stringify({payment_id}),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    reportNonPayment: async (payment_id) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}report-non-payment`, {
                method: "POST",
                body: JSON.stringify({payment_id}),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    reportPayment: async (data) => {
        try {
           
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            var formdata = new FormData();

            for(let prop in data) {
                formdata.append(prop, data[prop]);
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch(`${PaymentService.url}report-payment`, requestOptions)
            return response

        } catch (e) {  
            return false;
        }
    },

    generateCreditCheck: async (paymentId) =>{
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}generate-credit-check/${paymentId}`, {
                headers: headers
            })

            return response
        }catch (e) {
            console.log(e)
            return false
        }
    },

    extendTerm: async (payment_id, term) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}extend-term`, {
                method: "POST",
                body: JSON.stringify({payment_id, term}),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    attachInvoice: async (formdata) => {
        try {
           
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch(`${PaymentService.url}attach-invoice`, requestOptions)
            return response

        } catch (e) {  
            return false;
        }
    },

    requestExtendTerm: async (payment_id, days) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}request-extend-term`, {
                method: "POST",
                body: JSON.stringify({payment_id, days}),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    handleRequestExtendTerm: async (term_extension_request_id, accepted) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}handle-request-extend-term`, {
                method: "POST",
                body: JSON.stringify({term_extension_request_id, accepted}),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    getTermExtensionRequest: async (accepted = "all", page = 1, order = "") => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}get-term-extensions?accepted=${accepted}&page=${page}&order=${order}`, {
                headers: headers
            })
            return response
        } catch (e) {
            return false;
        }
    },

    getTotalPaymentsByUser: async () => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}get-total-payments-by-user`, {
                headers: headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    updatePayment: async (payment_id, data) => {
        try {
            const headers = PaymentService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${PaymentService.url}update-payment/${payment_id}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: headers
            })

            return response
        } catch (e) {
            return false;
        }
    },

    async exportData(exportOption, startDate, endDate, ownerId) {
        const fullUrl = `${this.url}export`;
        const response = await fetch(fullUrl, {
          method: 'POST',
          headers: this.headers,
          body: JSON.stringify({ 
            exportOption, 
            startDate, 
            endDate, 
            owner_id: ownerId 
          }),
        });
    
        if (!response.ok) {
          const errorDetails = await response.json();
          throw new Error(errorDetails.msg || 'Error en la exportación');
        }
    
        return response;
    }
}

export default PaymentService