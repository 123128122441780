<template>
    <v-main v-if="!success">
        <v-row class="main-row">
            <v-col 
                cols="2"
                class="bg-purple-darken-2 d-none d-md-flex align-center"
                style="background: rgb(245, 245, 245);"
            >
            <v-col
            cols="12" 
            >
                <v-img 
                    src="@/assets/logo.webp" 
                    width="180"
                    style="margin: auto;"
                    />
            </v-col>
            </v-col>
            
            <v-col
                md="10"
                cols="12"
                class="d-flex align-center"
            >   
                <v-col 
                    cols="12"
                    md="8"
                    offset-md="2"
                >
                <v-container id="register-container">
                <v-form 
                    ref="form"
                    v-model="valid"
                >
                    <v-container
                        style="height: 100%;"
                        class="align-center"
                    >
                    <v-col cols="12" class="text-center">
                        <h2>Registro de clientes</h2>
                        <h4 class="mt-2 mb-2">Ingresa los datos de contacto del cliente para que Mangxo pueda comunicarse con ellos.</h4>
                        <p></p>
                    </v-col>
                    <v-row>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                ref="business_name_input"
                                v-model="form.business_name"
                                label="Nombre de la empresa"
                                :rules="rules.required"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-select
                                v-model="form.business_type"
                                label="Figura jurídica"
                                :items="[
                                    { text: 'Persona física', value: 'person' },
                                    { text: 'Persona moral', value: 'company' }
                                ]"
                                :rules="rules.businessTypeRequired"
                                outlined
                            ></v-select>
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                v-model="form.contact_name"
                                label="Nombre del contacto"
                                :rules="rules.required"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        >
                            <v-text-field
                                v-model="form.contact_phone"
                                label="Télefono de contacto"
                                :rules="rules.phone"
                                outlined
                                autocomplete="off"
                                maxlength="10"
                            >
                                <template v-slot:prepend-inner>
                                    <span style="margin-top: 3px; display: inline-block;">+52</span>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model="form.contact_email"
                                label="Correo de contacto"
                                :rules="rules.email"
                                outlined
                                hint="Te avisaremos a este correo acerca del estado de tu solicitud"
                                persistent-hint
                            ></v-text-field>
                            <span></span>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <vuetify-money
                                v-model="form.credit_requested"
                                label="Línea de crédito deseada"
                                hint="Te avisaremos a este correo acerca del estado de tu solicitud"
                                persistent-hint
                                v-bind:options="options"
                                outlined
                            ></vuetify-money>
                        </v-col>
             
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model="form.supplier_referral_code"
                                label="Código de proveedor"
                                :rules="rules.required"
                                outlined
                            ></v-text-field>
                            <span>¿Olvidaste el <b>código</b>? Solicitalo <a target="_blank" href="https://api.whatsapp.com/send?phone=5218184647856&text=%C2%A1Hola!%20Olvide%20mi%20c%C3%B3digo%20de%20proveedor.">aqui</a> en segundos</span>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-center">
                            <v-btn 
                                color="green"
                                medium
                                fab
                                href="https://api.whatsapp.com/send?phone=5218184647856&text=%C2%A1Hola!%20Necesito%20ayuda%20registrando%20mis%20clientes."
                                target="_blank"
                            >
                                <v-icon
                                    medium
                                    color="white"
                                >   
                                mdi-whatsapp    
                                </v-icon>
                            </v-btn>
                                ¿Necesitas ayuda?
                        </v-col>
                        <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage"/>
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <v-btn
                                block
                                elevation="2"
                                outlined
                                rounded
                                large
                                :class="!valid ? 'btn-continue-disabled' : 'btn-continue'"
                                @click="handleSubmit"
                                :disabled="!valid"
                                style="font-weight: bold;"
                            >
                                Registrar
                            </v-btn>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-form>
            </v-container>
                </v-col>
            </v-col>
            <Loading v-if="loading"/>
    
        </v-row>
    </v-main>
    <v-main class="d-flex align-center" v-else>
        <v-container class="text-center">
            <div class="text-h4 font-weight-bold mb-4 mt-5">¡Cliente registrado!</div>
            <v-icon
                style="font-size: 80px !important; color: rgb(115, 46, 213);"
            >
                mdi mdi-check-circle
            </v-icon>
            <h2 class="mt-5 mb-5">Podrás ver el avance de la solicitud del cliente desde la plataforma.</h2>
            <v-btn
                elevation="2"
                outlined
                rounded
                large
                class="btn-continue mt-5"
                style="font-weight: bold;"
                @click="success = false"
            >
                REGISTRAR OTRO CLIENTE
            </v-btn>
        </v-container>
    </v-main>
 </template>

<script>

    import RegisterService from "@/services/registerService"
    import ErrorMessage from "@/components/register/ErrorMessage"
    import options from '@/config/money'
    import Loading from "@/components/register/Loading"

    export default {

        components: {
            ErrorMessage,
            Loading
        },

        data() {
            return {
                form: {
                    business_name: "",
                    contact_name: "",
                    contact_phone: "",
                    contact_email: "",
                    credit_requested: "",
                    business_type: "",
                    // supplier_id: ""
                    supplier_referral_code: ""
                },

                valid: false,
                rules: {
                    required: [
                        v => !!v || 'El campo es requerido',
                    ],
                    requiredCheckbox: [
                        v => v === true || 'El campo es requerido',
                    ],
                    phone: [
                        v => !!v || 'El campo es requerido',
                        v => v.length === 10 || "El número debe tener al menos 10 digitos ",
                        v => /^[0-9]+$/.test(v) || "Solo se permiten números",
                    ],
                    email: [
                        v => !!v || 'El campo es requerido',
                        v =>  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) ||  "Debe ingresar un correo valido"
                    ],
                    businessTypeRequired: [
                        v => v !== '' || 'Debes seleccionar una figura jurídica'
                    ]
                },
                errorMessage: "",
                loading: false,
                // searchLoading: false,
                success: false,
                // items: [],
                // search: null,
                // select: null,
                // suppliers: [
                //     {id:"9", name:"Regio Mecasa"},
                //     {id:"11", name:"DEX Materiales"},
                //     {id:"13", name:"Vyrisa"},
                //     {id:"14", name:"Potencia fluida"},
                //     {id:"15", name:"Plafones e interiores"},
                //     {id:"16", name:"Aceros Escobedo"},
                //     {id:"17", name:"Kirkot"},
                //     {id:"18", name:"Concretos Magno"},
                //     {id:"19", name:"Gama Materiales y Aceros"},
                //     {id:"20", name:"Proveedora de Plafones y Acabados"},
                //     {id:"22", name:"MAT MOVIL"},
                //     {id:"23", name:"SMART BLOCKS DE MONTERREY"},
                //     {id:"24", name:"SOLUCIONES MOVILES EN CONCRETO"},
                //     {id:"25", name:"Conekta Industrial Monterrey"},
                //     {id:"26", name:"TUMATSA MONTERREY"},
                //     {id:"27", name:"ACEROS Y TRITURADOS DE MONTERREY SA DE CV"},
                //     {id:"28", name:"Ferretera28"},
                //     {id:"29", name:"GETSA"},
                //     {id:"30", name:"TUMATSA LEON"},
                // ],
            }
        },

        mounted(){
            this.$refs.business_name_input.focus()
        },

        methods: {
            async handleSubmit() {
                const formDataToSend = {
                    ...this.form,
                    contact_phone: `52${this.form.contact_phone}`
                };

                this.loading = true

                const response = await RegisterService.step1(formDataToSend)
                const status = response.status
                const result = await response.json()

                this.loading = false

                switch (status) {
                    case 200:
                        this.success = true
                    break
                    case 422:
                        this.errorMessage = "Verifique los datos"
                    break
                    case 500:
                    case 403:
                        this.errorMessage = result.msg
                    break
                }
            },

            // querySelections (v) {
            //     this.searchLoading = true
            //     // Simulated ajax query
            //     setTimeout(() => {
            //         this.items = this.suppliers.filter(e => {
            //             return (e.name || '').toLowerCase().indexOf((v.name || '').toLowerCase()) > -1
            //         })
            //         this.searchLoading = false
            //     }, 500)
            // },
        },

        computed: {
            options: () => {
                return options
            }
        },

        watch: {
            // search (val) {
            //     val && val !== this.select && this.querySelections(val)
            // },

            success(val) {
                if (!val) {
                    this.form =  {
                        business_name: "",
                        contact_name: "",
                        contact_phone: "",
                        credit_requested: "",
                        supplier: ""
                    }
                }
            }
        },
    
    }
</script>

<!-- <style>
    #register-container {
        padding-right:8em;
        padding-left:8em;
    }
</style> -->