const DirectoryActivitiesServices = {

    url: `${process.env.VUE_APP_API_URL}directory-activities`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    create: async (data) => {
        try {

            const headers = DirectoryActivitiesServices.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
            
            const response = await fetch(`${DirectoryActivitiesServices.url}/create`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },
}

export default DirectoryActivitiesServices