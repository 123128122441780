<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    class="elevation-1"
    :loading="loading"
    loading-text="Cargando..."
    :options.sync="options" 
    @update:options="updatePaymentsOrderBy"
    :server-items-length="100"
  >
    <template v-slot:[`item.status.name`]="{ item }">
        <v-chip
          class="mt-1 mb-1 font-weight-bold"
          :color="getColorByCode(convertCode(item.status.name))"
          text-color="white"
          small
        >
         {{ translate_code(`${convertCode(item.status.name)}_singular`) }}
        </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item, index }">
      <MenuActionPayments 
        :item="item" 
        :index="index" 
        :page="page"
        @handleAction="handleAction"
        />
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      {{ item.amount | currency }}
    </template>
    <template v-slot:[`item.reserved_credit_line_id`]="{ item }">
      {{ item.reserved_credit_line_id ?? "No" }}
    </template>
     <!-- <template v-slot:[`item.term`]="{ item }">
      {{ item.term + " " + (item.term == 1 ? "día" : "días")}}
    </template> -->
    <template v-slot:[`item.invoice_url`]="{ item }">
      <v-chip
        :class="`${item.invoice_url ? 'green' : 'red'} font-weight-bold`"
        text-color="white"
        small
      >
        {{ item.invoice_url ? "Factura cargada" : "Factura no cargada" }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import translate_code from "../helpers/translate_code"
import MenuActionPayments from "@/components/MenuActionPayments.vue"

export default {
    props: ["headers", "items", "loading", "code", "page"],
    
    components: {
      MenuActionPayments
    },

    data() {
      return {
        options: {
          sortBy: [],
          sortDesc: []
        },
      }
    },

    methods: {

      updatePaymentsOrderBy() {
          // console.log({options: this.options})
          this.$emit("updatePaymentsOrderBy", this.options)
      },

      handleAction(item, code) {
        this.$emit("handleAction", item, code)
      },
      
      translate_code (key) {
        return translate_code(key)
      },

      convertCode (key) {
        const role = this.$UserManager.getMainRole()

        if (role == "supplier") {
          switch (key) {
            case "paid_to_mangxo":
            case "payment_in_financing":
              key = "paid"
            break
          }
        }

        return key
      },

      getColorByCode (status_code) {
        let color = "light"

        switch(status_code) {
          case "pending":
          case "payment_in_financing":
            color = "warning"
          break
          case "expired":
          case "non_payment":
          case "not_reported":
          case "paid_by_mangxo":
            color = "red"
          break
          case "paid":
          case "paid_to_mangxo":
            color = "success"
          break 
          case "to_confirm":
          // case "paid_by_mangxo":
            color = "primary"
          break
        }

        return color
      },
    },

    filters: {
      currency(value) {
          
          var formatter = new Intl.NumberFormat('es-MX', {
              style: 'currency',
              currency: 'MXN'
          });
          
          return formatter.format(value);
      }
    }
}
</script>
