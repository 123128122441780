const CustomerTokenService = {

    url: `${process.env.VUE_APP_API_URL}customers/`,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

    generate: async () => {
        try {
            const headers = CustomerTokenService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerTokenService.url}generate-token`, {
                method: 'POST',
                headers: headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    },

    getToken: async() => {
        try {
            const headers = CustomerTokenService.headers
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

            const response = await fetch(`${CustomerTokenService.url}generate-token`, {
                method: 'GET',
                headers: headers
            })
            
            return response
        } catch (e) {
            return false;
        }
    }
}

export default CustomerTokenService