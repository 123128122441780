<template>
    <v-main>
      <v-container
      class="py-8 px-6"

      >
        <v-row>
          <v-col
            lg="6"
            offset-lg="3" 
            md="12" 
            align="center"
          >
            <div class="text-h6 mb-5">Tu número de usuario: {{ $UserManager.getId() }}</div>
            <v-card>
              <v-card-title class="justify-center">
                Código de compra
            </v-card-title>
              <div class="text-h3 mt-3 mb-4" v-if="response.token">
                {{ response.token }}
                <div class="" style="font-size: 0.3em;" v-if="response.token && response.expire_in > 0">
                  Expirará en: {{ minutes }}:{{ seconds <= 9 ? '0' + seconds : seconds }}
                </div>
              </div>
              <v-col cols="12" sm="6" md="6">
                <v-btn
                  :color="copyButton.color"
                  outlined
                  @click="copyData"
                  :disabled="!response.token"
                  block
                >
                  <v-icon
                    color="light"
                  >
                    mdi {{ copyButton.icon }}
                  </v-icon>
                  {{ copyButton.text }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-btn
                  class="yellow amber lighten-1--text"
                  :loading="loading"
                  :disabled="loading"
                  @click="handleGenerateToken"
                  block
                  outlined
                >
                  Generar
                </v-btn>
              </v-col>  
            </v-card>
            <SharedToken />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </template>
  
<script>

import CustomerTokenService from '../services/customerTokenService'
import SharedToken from '../components/SharedToken.vue'
  
    export default {
        components: {
        SharedToken,
      },
    
      data() {
          return {
              response: {},
              timer: null,
              minutes: 0,
              seconds: 0,
              loading: false, // Controlador de loading
              copyButton: {
              text: 'Copiar código',
              color: 'dark',
              icon: 'mdi-clipboard',
              },
          };
      },
  
      mounted() {
          this.fetchToken();
      },

      beforeRouteLeave(to, from, next) {
          clearInterval(this.timer);
          // Continúa con la navegación
          next();
      },
    
      methods: {
        async fetchToken() {
            try {
                const response = await CustomerTokenService.getToken();
                const result = await response.json();
        
                if (result.token) {
                    this.response = result;
                    this.startTimer(result.expire_in); // Iniciar el contador con el tiempo restante
                }
            } catch (error) {
                console.error('Error al obtener el token:', error);
            }
        },
  
        async handleGenerateToken() {
            this.loading = true; // Iniciar loading
            try {
                const response = await CustomerTokenService.generate();
                const result = await response.json();
    
                if (result.token) {
                    this.response = result;
                    this.startTimer(result.expire_in); // Iniciar el contador con el nuevo token
                    this.resetCopyButton();
                }
            } catch (error) {
                console.error('Error al generar el token:', error);
            } finally {
                this.loading = false; // Finalizar loading
            }
        },
    
        startTimer(expireIn) {
            clearInterval(this.timer); // Detener cualquier temporizador previo
    
            this.minutes = Math.floor(expireIn / 60);
            this.seconds = expireIn % 60;
    
            this.timer = setInterval(async () => {
                if (this.seconds === 0) {
                    if (this.minutes === 0) {
                        clearInterval(this.timer); // Detener el temporizador si llega a 0
                        this.response.token = null; // Ocultar el token
                        await this.handleGenerateToken() 
                    } else {
                        this.minutes -= 1;
                        this.seconds = 59;
                    }
                } else {
                    this.seconds -= 1;
                }
            }, 1000);
        },
    
        copyData() {
            const data = this.response.token;
            navigator.clipboard.writeText(data);
    
            this.copyButton = {
            text: 'Código copiado',
            color: 'success',
            icon: 'mdi-clipboard-check',
            };
        },
    
        resetCopyButton() {
            this.copyButton = {
                text: 'Copiar código',
                color: 'dark',
                icon: 'mdi-clipboard',
            };
        },
    },
};
</script>
  