<template>
    <v-form 
        ref="form" 
        v-model="valid"
    >
    <v-row>
        <v-col cols="12 text-h6">
            <b>Documentos generales</b>
            <div class="text-subtitle-1">Archivos aceptados: <b>{{ extensionsList }}</b></div>
        </v-col>
        <v-row>
            <v-col
                cols="12"
                md="4"
                v-for="(file, index) in filesRequireds"
                :key="index"
            >   
                <v-card>
                    <v-card-text class="p-4 text-center" 
                        style="height: 150px !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;"
                    >
                        <div class="text-h7 font-weight-bold text-center">{{ file.label }}</div>
                        <v-file-input 
                            :ref="'fileInput' + (index + 1)"
                            variant="solo"
                            v-model="files[file.file_name]"
                            :rules="rules"
                            :accept="validExtensions"
                            class="d-none"
                        ></v-file-input>
                        <div style="color: red;">{{ errors[file.file_name] }}</div>
                        <v-icon 
                            large
                            @click="handleUploadClick(index + 1)"
                            v-if="!files[file.file_name]"
                        >
                            mdi mdi-cloud-upload
                        </v-icon>
                        <div class="text-center" v-else>
                            <v-icon 
                                large
                                color="green"
                            >
                                mdi mdi-check-circle
                            </v-icon>
                            <v-col>
                                <v-btn
                                    small
                                    @click="handleUploadClick(index + 1)"
                                >
                                    Editar   
                                </v-btn>
                            </v-col>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        
        <v-row>
            <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage"/>
            <v-col
            cols="4"
            md="4"
            offset="4"
            >
                <v-btn
                    block
                    elevation="2"
                    outlined
                    rounded
                    large
                    :class="!valid ? 'btn-continue-disabled' : 'btn-continue'"
                    @click="handleSubmit"
                    :disabled="!valid"
                >
                    Continuar
                </v-btn>
            </v-col>
        </v-row>
    </v-row>        
</v-form>  
</template>

<script>
    import RegisterService from "@/services/registerService"
    import filesRules from "@/config/filesRules"
    import ErrorMessage from "@/components/register/ErrorMessage"

    export default {
        
        components: {
            ErrorMessage
        },

        data() {
            return {
                files: {
                    official_identification_legal_representative: null,
                    constitutive_act: null,
                    fiscal_situation: null,
                },
                
                filesRequireds: [],

                valid: false,

                rules: [
                    v => !!v || 'Debe cargar un archivo',
                    v => (v && v.size > 0) || 'Debe cargar un archivo',
                    v => (v && ((v.size /  Math.pow(1024,1)) < filesRules.maxSize)) || 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB",
                ],

                errors: {
                    official_identification_legal_representative: null,
                    constitutive_act: null,
                    tax_address: null,
                },

                errorMessage: null
            }
        },

        mounted () {

            if (this.$route.query.business_type) {
                localStorage.setItem("businessType", this.$route.query.business_type)
            }

            const businessType = localStorage.getItem('businessType');
            this.updateFilesRequireds(businessType);
            this.setMonths();
        },

        methods: {
            // Actualizar `filesRequireds` según `businessType`
            updateFilesRequireds(businessType) {
                if (businessType === 'company') {
                    this.filesRequireds = [
                        { label: "Identificación del representante legal", file_name: "official_identification_legal_representative" },
                        { label: "Acta constitutiva", file_name: "constitutive_act" },
                        { label: "Constancia de situación fiscal actualizado (menor a 3 meses)", file_name: "fiscal_situation" },
                        { label: "Comprobante de domicilio fiscal actualizado (menor a 3 meses)", file_name: "tax_address" }
                    ];
                } else {
                    this.filesRequireds = [
                        { label: "Identificación del representante legal", file_name: "official_identification_legal_representative" },
                        { label: "Constancia de situación fiscal actualizado (menor a 3 meses)", file_name: "fiscal_situation" },
                        { label: "Comprobante de domicilio fiscal actualizado (menor a 3 meses)", file_name: "tax_address" }
                    ];
                }
            },
            async handleSubmit () {

                this.$emit("handleLoading", true)

                if (this.files.length == 0) {
                    this.errorMessage = "Debe seleccionar un archivo"
                    return
                }

                var formdata = new FormData();

                for(let prop in this.files) {
                    formdata.append(prop, this.files[prop]);
                }

                const response = await RegisterService.step6CompanyInfo(formdata)
                const status = response.status
                const result = await response.json()

                this.$emit("handleLoading", false)

                switch (status) {
                    case 200:
                        this.$router.push({ path: 'bank-statements' });
                    break
                    case 422:
                        this.errorMessage = "Verifique los datos"
                    break
                    case 500:
                    case 404:
                    case 403:
                        this.errorMessage = result.msg
                    break
                }
            },

            setMonths () {
                // get the previous 3 months
                const date = new Date();
                let currentMonth = date.getMonth() - 1;  

                for(let i=1; i <= 3; i++) {
                    let month = currentMonth - i

                    if (month < 0) {
                        month = month + 12
                    }
                    this.months.push(month)
                }
            },

            handleUploadClick (file_upload_id) {
                this.$refs["fileInput" + file_upload_id][0].$refs.input.click()
            },
        },

        computed: {
            validExtensions( ) {
                return filesRules.extensions.toString()
            },
            
            extensionsList () {
                return filesRules.extensionsList.toString()
            }
        },

        // Improve
        watch: {
            "files.official_identification_legal_representative"() {
                if ((this.files.official_identification_legal_representative && ((this.files.official_identification_legal_representative.size /  Math.pow(1024,1)) < filesRules.maxSize))) {
                    this.errors["official_identification_legal_representative"] = null
                    return
                }  

                this.errors["official_identification_legal_representative"] = 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB"
                this.files.official_identification_legal_representative = null
            },

            "files.constitutive_act"() {
                if ((this.files.constitutive_act && ((this.files.constitutive_act.size /  Math.pow(1024,1)) < filesRules.maxSize))) {
                    this.errors["constitutive_act"] = null
                    return
                }  

                this.errors["constitutive_act"] = 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB"
                this.files.constitutive_act = null
            },

            "files.tax_address"() {
                if ((this.files.tax_address && ((this.files.tax_address.size /  Math.pow(1024,1)) < filesRules.maxSize))) {
                    this.errors["tax_address"] = null
                    return
                }  

                this.errors["tax_address"] = 'El archivo supera el maximo tamaña permitido: ' + filesRules.maxSize / 1024 + "MB"
                this.files.tax_address = null
            }
        },

    }
</script>

<style>
    .v-messages__message {
        display: block !important;
    }
</style>