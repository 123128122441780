<template>
<v-dialog
    v-model="show"
    max-width="500"
    >
    <v-card class="pa-6">
      <v-card-title>Registrar cliente</v-card-title>
      <v-card-text>
        <v-form 
          ref="form"
          v-model="valid"
    
        >

          <v-text-field
            v-model="form.business_name"
            label="Nombre de la empresa"
            :rules="rules.required"
            outlined
          ></v-text-field>

          <v-select
              v-model="form.business_type"
              label="Figura jurídica"
              :items="[
                  { text: 'Persona física', value: 'person' },
                  { text: 'Persona moral', value: 'company' }
              ]"
              :rules="rules.businessTypeRequired"
              outlined
          ></v-select>
                    
          <v-text-field
            v-model="form.contact_name"
            label="Nombre de contacto"
            :rules="rules.required"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="form.contact_phone"
            label="Teléfono de contacto"
            :rules="rules.phone"
            maxlength="10"
            prefix="+52"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="form.contact_email"
            label="Correo de contacto"
            :rules="rules.email"
            outlined
          ></v-text-field>

          <vuetify-money
            v-model="form.credit_requested"
            label="Línea de crédito deseada"
            valueWhenIsEmpty=""
            v-bind:options="options"
            outlined
          />
          
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="show = false">
          Cerrar
        </v-btn>
        <v-btn
          text
          style="background: #732C9C; color: #fff"
          @click="handleSubmit"
          :disabled="(!valid || inSubmit) || !valid"
        >
          Registrar
        </v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import options from '@/config/money'
import CustomerService from "@/services/customerService"

export default {
    props: [
      "visible"
    ],

    data() {
        return {
            form: {
              business_name: null,
              contact_name: null,
              contact_phone: null,
              contact_email: null,
              credit_requested: null,
              business_type: null,
              origin: "frontdesk"
            },
            inSubmit: false,
            valid: false,
            rules: {
              required: [ v => !!v || 'El campo es requerido'],
              email: [v => /.+@.+\..+/.test(v) || 'Correo no válido'],
              phone: [v => /^\d{10}$/.test(v) || 'El número debe incluir +52 y tener 10 dígitos.'],
              businessTypeRequired: [
                v => v !== '' || 'Debes seleccionar una figura jurídica'
              ]
            },
        }
    },

    methods: {
        async handleSubmit() {

          this.form.contact_phone = "52" + this.form.contact_phone

          const response = await CustomerService.createStep1(this.form)
          const status = response.status

          const data = {
            success: false,
            msg: "Ocurrio un error"
          }

          if (status == 200) {
            data.success = true
            data.msg = "Cliente registrado"
          }

          this.reset()
          this.$emit("close")
          this.$emit("showDialogAlert", data)
        },

        reset () {
          this.form.business_name = "",
          this.form.contact_name = "",
          this.form.business_type = "",
          this.form.contact_phone = "",
          this.form.contact_email = "",
          this.form.credit_requested = "",
          this.form.origin = "frontdesk"
        }
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      },
      options: () => {
        return options
      }
    },
}

</script>